import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/vercel/path0/node_modules/.pnpm/@highlight-run+next@7.6.11_@opentelemetry+api@1.9.0_next@15.0.3_@babel+core@7.26.0_@opentelem_vodhzfgo2ms2ac3y7425zcphty/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@19._w3wbohebect6rivz6qwoyd57yu/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/jotai@2.8.4_@types+react@19.0.1_react@19.0.0/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto_Serif\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"variable\":\"--font-roboto-serif\"}],\"variableName\":\"robotoSerif\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/node_modules/.pnpm/react-toastify@10.0.6_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-toastify@10.0.6_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/Global/LoginButton.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
